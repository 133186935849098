import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { cn } from '@/utils/cn'
import IconCarPawn from './assets/icon_car_pawn.svg'
import IconPawn from './assets/icon_pawn.svg'
import IconSell from './assets/icon_sell.svg'

export function MobileSubHeader() {
  const { t } = useTranslation()

  return (
    <div className="md:hidden bg-white flex flex-row justify-around items-center font-semibold px-2">
      <SegmentLink path="/pfandkredit">
        <IconPawn className="size-[22px] m-px mr-0" />
        {t('common:header.pfandkredit')}
      </SegmentLink>
      <SegmentLink path="/autopfand">
        <IconCarPawn />
        {t('common:header.autopfand')}
      </SegmentLink>
      <SegmentLink path="/verkaufen-mit-cashy">
        <IconSell className="size-5 m-0.5 mr-0" />
        {t('common:header.verkaufen')}
      </SegmentLink>
    </div>
  )
}

function SegmentLink({
  path,
  children,
}: {
  path: string
  children: React.ReactNode
}) {
  const isPathActive = usePathname() === path

  return (
    <Link className="flex-1 flex justify-center" href={path}>
      <span
        className={cn(
          'pl-0.5 pr-1 gap-1 rounded-md border text-xs inline-flex items-center',
          isPathActive ? 'border-black' : 'border-transparent',
        )}
      >
        {children}
      </span>
    </Link>
  )
}

export const MOBILE_SUB_HEADER_PATHS = [
  '/',
  '/pfandkredit',
  '/autopfand',
  '/verkaufen-mit-cashy',
]

import { cn } from '@/utils/cn'

export function GridItem({
  area,
  hidden,
  zIndex = 100,
  style,
  className,
  ...props
}: {
  area: string
  hidden?: boolean
  zIndex?: number
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn('h-full', hidden && 'hidden', className)}
      style={{
        gridArea: area,
        zIndex: zIndex,
        ...style,
      }}
      {...props}
    />
  )
}

export function Center({
  center,
  className,
  ...props
}: {
  center?: boolean
} & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'h-full flex items-center',
        center && 'justify-center',
        className,
      )}
      {...props}
    />
  )
}

export function MainNavigation({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) {
  return (
    <span
      className={cn('relative text-black text-sm font-semibold', className)}
      {...props}
    />
  )
}

export function Dropdown({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'relative flex items-center justify-center cursor-pointer group',
        className,
      )}
      {...props}
    />
  )
}

export function DropdownMenu({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'hidden group-hover:block absolute top-full bg-white shadow-lg rounded-md min-w-44',
        className,
      )}
      {...props}
    />
  )
}

export function DropdownItem({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'text-black text-sm font-semibold p-4 rounded-sm hover:text-primary hover:bg-primaryHover cursor-pointer',
        className,
      )}
      {...props}
    />
  )
}

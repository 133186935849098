import { storyblokEditable } from '@storyblok/react'
import { FunctionComponent, ReactNode, useMemo } from 'react'
import NextStoryblokImage from '@/app/common/components/StoryblokImage/NextStoryblokImage'
import {
  IconStoryblok,
  IconsStoryblok,
} from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'
import styles from './icons.module.css'

export default function Icons({ blok }: { blok: IconsStoryblok }) {
  const animationVars = {
    '--animation-duration': `${blok.AnimationDuration}s`,
  } as React.CSSProperties

  return (
    <div
      {...storyblokEditable(blok)}
      className="py-8 mx-auto w-full md:max-w-screen-md"
    >
      <div className="w-full text-sm text-center text-cashy-gray uppercase font-medium pb-3 leading-relaxed">
        {blok.Title}
      </div>
      <div className="relative w-min max-w-full overflow-x-hidden mx-auto [mask-image:_linear-gradient(to_right,transparent_0,_white_100px,_white_calc(100%-100px),transparent_100%)]">
        <div className={cn('w-max', styles.slideTrack)} style={animationVars}>
          {blok.Icons.map((icon) => (
            <OptionalLink blok={icon}>
              <IconImage blok={icon} />
            </OptionalLink>
          ))}
        </div>
        {/* 2nd slider creates the illusion of an infinite loop */}
        <div
          className={cn('absolute top-0 w-max', styles.secondSlideTrack)}
          style={animationVars}
        >
          {blok.Icons.map((icon) => (
            <OptionalLink blok={icon}>
              <IconImage blok={icon} />
            </OptionalLink>
          ))}
        </div>
      </div>
    </div>
  )
}

const OptionalLink: FunctionComponent<{
  blok: IconStoryblok
  children: ReactNode
}> = ({ blok, children }) => {
  if (blok.Link?.url) {
    return (
      <div className="inline">
        <a href={blok.Link.url}>{children}</a>
      </div>
    )
  }

  return children
}

const IconImage: FunctionComponent<{
  blok: IconStoryblok
}> = ({ blok }) => {
  const maxHeightInPx = 54
  const fallbackWidth = 100

  // determine the optimal width given the maximum height
  const { width, ratio } = useMemo(() => {
    const regex = /\d+x\d+/
    const match = blok.Icon.filename.match(regex)

    if (!match || match.length !== 1) {
      console.warn(`Couldnt determine original size of icons blok`, {
        match,
        filename: blok.Icon.filename,
      })
      return { width: fallbackWidth, ratio: 1 }
    }
    const [originalWidth, originalHeight] = match[0].split('x')
    const ratio = Number(originalWidth) / Number(originalHeight)

    // prevent image upscaling
    const width = Math.min(
      Number((maxHeightInPx * ratio).toFixed(0)),
      Number(originalWidth),
    )
    return { width, ratio: +ratio.toFixed(2) }
  }, [blok, maxHeightInPx])

  return (
    <NextStoryblokImage
      image={blok.Icon}
      width={width}
      height={+(width / ratio).toFixed(2)}
      className="inline mx-6"
    />
  )
}

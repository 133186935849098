import Observer from '@researchgate/react-intersection-observer'
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react'
import React, { useCallback, useContext, useMemo } from 'react'
import styled, { DefaultTheme, useTheme } from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import * as tracking from '@/helpers/tracking'
import { media } from '@/style/helpers'
import { SectionStoryblok } from '@/types/storyblok-component-types'

export default function Section({ blok }: { blok: SectionStoryblok }) {
  const verticalSpacing = blok.vertical_spacing
  const backgroundColor = blok.background_color?.color
  const maxWidthInPx = sizeByOption[blok.size]
  const hasBackground =
    backgroundColor ||
    blok.bottom_left_bg_img?.filename ||
    blok.bottom_right_bg_img?.filename
  const sizes = '30vw'

  const handleContainerObserverChange = useCallback(
    (e: IntersectionObserverEntry) => {
      if (e.isIntersecting && blok.id) {
        tracking.trackEvent('section_start_visible', {
          id: blok.id,
        })
      }
    },
    [blok.id],
  )

  const handleEndObserverChange = useCallback(
    (e: IntersectionObserverEntry) => {
      if (e.isIntersecting && blok.id) {
        tracking.trackEvent('section_end_visible', {
          id: blok.id,
        })
      }
    },
    [blok.id],
  )

  return (
    <>
      <div id={blok.id} className="scroll-mt-[var(--header-height)]" />
      <Observer
        onChange={handleContainerObserverChange}
        rootMargin="0px 0px 200px 0px"
      >
        <Container
          {...storyblokEditable(blok)}
          $backgroundColor={backgroundColor}
          $verticalSpacingAmount={verticalSpacing}
          $verticalSpacingType={hasBackground ? 'padding' : 'margin'}
          data-section-id={blok.id}
        >
          <Observer onChange={handleEndObserverChange}>
            <EndObserverElement aria-hidden />
          </Observer>

          {blok.bottom_left_bg_img?.filename && (
            <BottomLeftImage>
              <StoryblokImage image={blok.bottom_left_bg_img} sizes={sizes} />
            </BottomLeftImage>
          )}

          {blok.bottom_right_bg_img?.filename && (
            <BottomRightImage>
              <StoryblokImage image={blok.bottom_right_bg_img} sizes={sizes} />
            </BottomRightImage>
          )}

          <RichTextWrapper>
            <StoryblokRichText document={blok.caption} />
          </RichTextWrapper>

          <SectionContext.Provider
            value={{
              maxContentWidthBreakpoint: maxWidthInPx,
              anchorId: blok.id,
            }}
          >
            {blok.body?.map((childBlok: SbBlokData) => (
              <Content
                $maxWidth={maxWidthInPx}
                key={childBlok._uid}
                $verticalSpacing={verticalSpacing}
              >
                <StoryblokComponent blok={childBlok} />
              </Content>
            ))}
          </SectionContext.Provider>
        </Container>
      </Observer>
    </>
  )
}

interface SectionContext {
  maxContentWidthBreakpoint: number
  anchorId: string | undefined
}

const SectionContext = React.createContext<SectionContext | undefined>(
  undefined,
)

function useMaxContentWidthBreakpoint() {
  return useContext(SectionContext)?.maxContentWidthBreakpoint
}

export function useSectionAnchorId() {
  return useContext(SectionContext)?.anchorId
}

type ImageSizeSpecifier = [keyof DefaultTheme['breakpoints'], string]

export function useImageSizes(
  viewportWidthPercentage: number,
  ...baseSizes: ImageSizeSpecifier[]
) {
  const { breakpoints } = useTheme()
  const baseSizesString = baseSizes
    .map((s) => `(max-width: ${breakpoints[s[0]]}px) ${s[1]}`)
    .join(', ')

  const maxContentWidthBreakpoint = useMaxContentWidthBreakpoint()
  const maxImageWidth = useMemo(
    () =>
      maxContentWidthBreakpoint &&
      Math.round(
        (maxContentWidthBreakpoint - 2 * maxHorizontalPaddingRem * 16) *
          (viewportWidthPercentage / 100),
      ),
    [maxContentWidthBreakpoint, viewportWidthPercentage],
  )

  return (
    baseSizesString +
    ', ' +
    (maxContentWidthBreakpoint
      ? `(max-width: ${maxContentWidthBreakpoint}px) ${viewportWidthPercentage}vw, ${maxImageWidth}px`
      : `${viewportWidthPercentage}vw`)
  )
}

const sizeByOption = {
  wide: 1500,
  medium: 1200,
  slim: 1000,
  'extra-slim': 800,
}

const Container = styled.section<{
  $backgroundColor?: string
  $verticalSpacingAmount: SectionStoryblok['vertical_spacing']
  $verticalSpacingType: 'padding' | 'margin'
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${({ $backgroundColor }) =>
    $backgroundColor || 'transparent'};

  ${(props) => props.$verticalSpacingType}-top: ${({
    theme,
    $verticalSpacingAmount: $verticalSpacing,
  }) => getVerticalSpacing(theme, $verticalSpacing, 'xs')};

  ${(props) => props.$verticalSpacingType}-bottom: ${({
    theme,
    $verticalSpacingAmount: $verticalSpacing,
  }) => getVerticalSpacing(theme, $verticalSpacing, 'xs')};

  ${({ theme, $verticalSpacingAmount, $verticalSpacingType }) =>
    (['sm', 'md', 'lg'] as const)
      .map(
        (breakpoint) => `
          ${media[breakpoint]} {
            ${$verticalSpacingType}-top: ${getVerticalSpacing(
              theme,
              $verticalSpacingAmount,
              breakpoint,
            )};

            ${$verticalSpacingType}-bottom: ${getVerticalSpacing(
              theme,
              $verticalSpacingAmount,
              breakpoint,
            )};
          }
        `,
      )
      .join('\n')}
`

function getVerticalSpacing(
  theme: DefaultTheme,
  verticalSpacing: SectionStoryblok['vertical_spacing'],
  breakpoint: keyof DefaultTheme['sections']['normal' | 'large'],
) {
  return (
    theme.sections[verticalSpacing === 'medium' ? 'normal' : 'large'][
      breakpoint
    ] + 'rem'
  )
}

const maxHorizontalPaddingRem = 2.5
const Content = styled.div<{
  $maxWidth: number
  $verticalSpacing: SectionStoryblok['vertical_spacing']
}>`
  /* fixes background images overlapping content */
  z-index: 1;
  overflow: hidden;

  width: 100%;
  display: flex;
  justify-content: center;

  max-width: ${({ $maxWidth }) => $maxWidth}px;

  padding: 0 1.5rem;
  ${media.xs} {
    padding: 0 ${maxHorizontalPaddingRem}rem;
  }

  &:not(:last-child) {
    margin-bottom: ${({ $verticalSpacing }) =>
      $verticalSpacing === 'large' ? 3 : 2}rem;
  }

  > div {
    width: 100%;
  }
`

const EndObserverElement = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
`

const BottomLeftImage = styled.div`
  position: absolute;
  height: 100%;
  width: 30%;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column-reverse;
`

const BottomRightImage = styled(BottomLeftImage)`
  left: auto;
  right: 0;
`

const RichTextWrapper = styled(RichTextParagraphStyles)`
  padding: 0 1.5rem;

  & h1 {
    text-align: center;
  }

  & h2 {
    text-align: center;
  }

  ${media.xs} {
    padding: 0 ${maxHorizontalPaddingRem}rem;
  }
`

import CartIcon from '../../../assets/ico_desktop_cart.svg'
import Link from 'next/link'
import { FunctionComponent } from 'react'
import { cn } from '@/utils/cn'

interface CartButtonProps {
  itemsCount: number
}

const CartButton: FunctionComponent<CartButtonProps> = ({ itemsCount }) => {
  return (
    <Link href="/checkout/items" passHref>
      <div className="flex flex-col items-center">
        <div id="header_cart" className="flex relative size-10">
          <CartIcon className="h-full" />
          <span
            className={cn(
              'text-primary font-bold text-sm absolute',
              itemsCount >= 10 ? 'right-[0.74rem]' : 'right-4',
            )}
          >
            {itemsCount}
          </span>
        </div>
      </div>
    </Link>
  )
}

export default CartButton

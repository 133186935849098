import AccountButton from '../Header/AccountButton/AccountButton'
import CartButton from '../Header/CartButton/CartButton'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import { StoryblokComponent } from '@storyblok/react'
import { useRouter } from 'next/router'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import MenuButton from '@/app/common/components/Header/MenuButton/MenuButton'
import { SearchBarButton } from '@/app/common/components/Header/SearchBarButton/SearchBarButton'
import {
  Center,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  GridItem,
  MainNavigation,
} from '@/app/common/components/Header/components'
import Link from '@/app/common/components/Link/Link'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import {
  CtaButtonStoryblok,
  HeaderLinkStoryblok,
} from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'
import styles from './header.module.css'

interface Props {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
  navigationBar?: (HeaderLinkStoryblok | CtaButtonStoryblok)[]
}

export function DesktopPrimaryHeader({
  menuOpen,
  toggleMenu,
  itemsCount,
  navigationBar,
}: Props) {
  const router = useRouter()
  const isProfile = router.route.includes('/profile')
  const isCheckout = router.pathname.includes('/checkout')
  const isFinalizeAccountSetup = router.pathname.includes(
    '/finalize-account-setup',
  )

  return (
    <div className={cn(styles.desktopPrimaryHeader, 'hidden lg:grid bg-white')}>
      <GridItem area="menu">
        <Center center>
          <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        </Center>
      </GridItem>
      <GridItem area="logo">
        <Center>
          <HeaderLogo />
        </Center>
      </GridItem>

      {!isProfile && (
        <GridItem area="search">
          <Center>
            <SearchBarButton />
          </Center>
        </GridItem>
      )}
      <section className="flex [grid-area:categories] h-full items-center justify-self-end px-4 py-3 text-nowrap gap-4 [&_.cta-button]:text-[0.9rem]">
        {navigationBar?.map((item) =>
          item.component === 'cta-button' ? (
            <StoryblokComponent blok={item} key={item._uid} />
          ) : item.header_child_links?.length ? (
            <Dropdown key={item._uid}>
              <Link href={getStoryBlokLink(item.link)}>
                <MainNavigation>{item.caption}</MainNavigation>{' '}
              </Link>
              <DropdownMenu
                className={cn(itemsCount == 0 ? '-right-3' : '-left-3')}
              >
                {item.header_child_links.map((childLink) => (
                  <Link
                    href={getStoryBlokLink(childLink.link)}
                    key={childLink.caption}
                  >
                    <DropdownItem>{childLink.caption}</DropdownItem>
                  </Link>
                ))}
              </DropdownMenu>
            </Dropdown>
          ) : (
            <Link
              key={item._uid}
              href={getStoryBlokLink(item.link) ?? '#'}
              centerLinkContent
              useNextLink={item.link?.linktype !== 'url'}
            >
              <MainNavigation>{item.caption}</MainNavigation>
            </Link>
          ),
        )}
      </section>

      {!isCheckout && !isFinalizeAccountSetup && itemsCount > 0 && (
        <GridItem zIndex={1} area="cart">
          <Center center>
            <CartButton itemsCount={itemsCount} />
          </Center>
        </GridItem>
      )}

      {!isCheckout && !isFinalizeAccountSetup && <AccountButton />}

      {!isCheckout && (
        <GridItem zIndex={1} area="language">
          <LanguageSelector />
        </GridItem>
      )}
    </div>
  )
}

import Observer from '@researchgate/react-intersection-observer'
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react'
import React, { useCallback } from 'react'
import * as tracking from '@/helpers/tracking'
import { Sectionv2Storyblok } from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'

export default function SectionV2({ blok }: { blok: Sectionv2Storyblok }) {
  const handleContainerObserverChange = useCallback(
    (e: IntersectionObserverEntry) => {
      if (e.isIntersecting && blok.id) {
        tracking.trackEvent('section_start_visible', {
          id: blok.id,
        })
      }
    },
    [blok.id],
  )

  const handleEndObserverChange = useCallback(
    (e: IntersectionObserverEntry) => {
      if (e.isIntersecting && blok.id) {
        tracking.trackEvent('section_end_visible', {
          id: blok.id,
        })
      }
    },
    [blok.id],
  )

  return (
    <div
      id={blok.id}
      className={cn(
        blok.visible_on_mobile === false ? 'hidden' : 'block',
        blok.visible_on_tablet === false ? 'sm:hidden' : 'sm:block',
        blok.visible_on_desktop === false ? 'md:hidden' : 'md:block',
      )}
    >
      <Observer onChange={handleContainerObserverChange}>
        <div
          {...storyblokEditable(blok)}
          data-section-id={blok.id}
          style={{
            backgroundColor: blok.background_color?.color ?? 'transparent',
          }}
          className="flex flex-col items-center"
        >
          {blok.body?.map((childBlok: SbBlokData) => (
            <div key={childBlok._uid} className="flex justify-center w-full">
              <StoryblokComponent blok={childBlok} />
            </div>
          ))}

          <Observer onChange={handleEndObserverChange}>
            <div className="hidden" />
          </Observer>
        </div>
      </Observer>
    </div>
  )
}

import Success from '../../../common/assets/ico_ilustracao.svg'
import { Button } from '@material-ui/core'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '@/style/components/Text'
import { media } from '@/style/helpers'

interface RegistrationSuccessProps {
  title: string
  description: string
  buttonLabel: string
  onClickReturn: () => void
}

export const RegistrationSuccess: React.FC<RegistrationSuccessProps> = ({
  title,
  description,
  buttonLabel,
  onClickReturn,
}) => {
  const [seconds, setSeconds] = useState(10)
  useEffect(() => {
    const intervalId = setInterval(
      () =>
        setSeconds((s) => {
          if (--s === 0) {
            clearInterval(intervalId)
            onClickReturn()
          }

          return s
        }),
      1_000,
    )

    return () => clearInterval(intervalId)
  }, [onClickReturn])

  return (
    <Wrapper>
      <SuccessStyled className="inline" />
      <div style={{ marginBottom: '10px' }}>
        <Text.lg weight="semibold" uppercase>
          {title}
        </Text.lg>
      </div>
      <div>
        <Text.ms>{description}</Text.ms>
      </div>
      <ReturnButton type="button" onClick={onClickReturn}>
        {`${buttonLabel} (${seconds})`}
      </ReturnButton>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: center;
  padding-top: 48px;
`

const SuccessStyled = styled(Success)`
  margin-bottom: 23px;
`

const ReturnButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 1rem auto 2rem auto;
  border: 2px solid #dfe1e5;
  width: calc(100% - 3rem);
  font-size: 0.875rem;

  ${media.xs} {
    font-size: 1rem;
  }

  ${media.md} {
    width: calc(75% - 3rem);
  }

  ${media.lg} {
    width: calc(50% - 3rem);
  }
`

import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import NextStoryblokImage from '@/app/common/components/StoryblokImage/NextStoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { HeroCardStoryblok } from '@/types/storyblok-component-types'
import { cn } from '@/utils/cn'

export default function HeroCard({ blok }: { blok: HeroCardStoryblok }) {
  return (
    <div className="flex justify-center p-4 md:p-12 w-full">
      <div className="max-w-[1400px] w-full" {...storyblokEditable(blok)}>
        {/** Desktop Version*/}
        <div
          className="md:flex justify-around items-center gap-6 w-full hidden"
          style={{ height: blok.desktop_height + 'px' }}
        >
          <RichTextParagraphStyles className="flex-1 text-left break-words">
            <StoryblokRichText document={blok.desktop_content} />
          </RichTextParagraphStyles>

          <div className="flex-1 relative h-full">
            <NextStoryblokImage
              key={blok.hero_image_desktop.id}
              image={blok.hero_image_desktop}
              fill
              className="object-cover object-center rounded-xl"
              sizes="(max-width: 1400px) 50vw, 700px"
            />
          </div>
        </div>

        {/** Mobile Version*/}
        <div
          className="relative w-full rounded-xl overflow-hidden md:hidden"
          style={{
            height: blok.mobile_height + 'px',
          }}
        >
          <NextStoryblokImage
            key={blok.hero_image_mobile.id}
            image={blok.hero_image_mobile}
            fill
            className="object-cover object-center"
          />

          <div
            className="absolute inset-0"
            style={
              {
                backgroundImage:
                  'linear-gradient(to bottom, var(--gradient-top) 20%, var(--gradient-bottom))',
                '--gradient-bottom':
                  blok.mobile_gradient_color_bottom?.color || transparent,
                '--gradient-top':
                  blok.mobile_gradient_color_top?.color || transparent,
              } as React.CSSProperties
            }
          />

          <div
            className={cn(
              'absolute inset-0 flex flex-col items-center text-center px-6 py-16',
              mobileAlignmentTw[blok.mobile_content_alignment || 'center'],
            )}
          >
            <RichTextParagraphStyles className="break-words">
              <StoryblokRichText document={blok.mobile_content} />
            </RichTextParagraphStyles>
          </div>
        </div>
      </div>
    </div>
  )
}

const mobileAlignmentTw: Record<
  Exclude<HeroCardStoryblok['mobile_content_alignment'], ''>,
  string
> = {
  top: 'justify-start',
  center: 'justify-center',
  bottom: 'justify-end',
}

const transparent = `rgba(0, 0, 0, 0)`

import * as Sentry from '@sentry/nextjs'
import config from '@/config'
import { MultilinkStoryblok } from '@/types/storyblok-component-types'

export function getStoryBlokLink(link?: MultilinkStoryblok): string {
  try {
    return getStoryBlokLinkOrThrow(link)
  } catch (error) {
    console.error('Failed to get link', {
      link,
      message: (error as any).message,
    })
    Sentry.captureException(error, {
      extra: {
        link,
      },
    })
    return ''
  }
}

function getStoryBlokLinkOrThrow(link?: MultilinkStoryblok): string {
  if (!link) {
    return ''
  }

  const { linktype, story, url, anchor } = link
  switch (linktype) {
    case 'story': {
      if (!story) {
        return ''
      }
      const baseUrl = stripExtrasFromSlug(story.full_slug)
      return anchor ? `${baseUrl}#${anchor}` : baseUrl
    }
    case 'url':
      if (url == null) {
        throw new Error('Type is url, but no url found')
      }
      return url
    case 'asset':
      if (url == null) {
        throw new Error('Type is asset, but no url found')
      }
      return url
    default:
      throw new Error(`Unknown type: ${linktype}`)
  }
}

export function stripExtrasFromSlug(slug: string) {
  // languages
  if (slug.startsWith('en/')) {
    slug = slug.replace('en/', '')
  }

  // region codes
  if (slug.startsWith('de/')) {
    return config.rootUrl.germany + slug.replace('de', '')
  }

  if (slug.startsWith('at/')) {
    return config.rootUrl.austria + slug.replace('at', '')
  }

  return slug
}

import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil'
import { useLazyQuery, useMutation, useQuery } from '@/app/common/graphql/hooks'
import config from '@/config'
import * as tracking from '@/helpers/tracking'
import useCompany from '@/helpers/useCompany'
import useShowSnackbar from '@/helpers/useShowSnackbar'
import {
  CreateDealDocument,
  CreateDealForCustomerDocument,
  CreateDealForCustomerMutation,
  CreateDealForCustomerMutationVariables,
  CreateDealMutation,
  CreateDealMutationVariables,
  DealCalculationDocument,
  DealCalculationQuery,
  DealCalculationQueryVariables,
  DealItemCalculationArgs,
  EDealType,
  ETransportMode,
  GetItemCategoriesDocument,
  GetItemCategoriesQuery,
  GetItemCategoriesQueryVariables,
  ItemAnswerArgs,
  ManualFeeDefinitionArgs,
  PersistedDealCalculation,
  Scalars,
} from '@/types/gql/graphql'
import { dayjs } from '@/utils/time'
import {
  checkoutDateState,
  dealCalculationState,
  dealDurationInDaysState,
  dealIdState,
  dealTypeState,
  feedbackState,
  guestEmailState,
  isCustomDealPayoutOverwrittenState,
  isSimplifiedPawnCheckoutState,
  knpDebtState,
  paymentDataState,
  selectedItemsState,
  transportDataState,
  transportFormStateCheckout,
} from './checkout.state'

export const buildDealCalculationVariables = ({
  companyId,
  dealType,
  date,
  items,
  pickupTransportMode,
  dropoffTransportMode,
  shouldOverwritePayoutAmount = false,
  overwrittenPayoutAmount = 0,
  shouldOverwriteManualFees = false,
  manualFeeDefinitionsArgs = [],
  durationInDays,
}: {
  companyId: Scalars['ObjectId']['output']
  dealType: EDealType
  date?: Date
  items: DealItemCalculationArgs[]
  pickupTransportMode?: ETransportMode
  dropoffTransportMode?: ETransportMode
  shouldOverwritePayoutAmount?: boolean
  overwrittenPayoutAmount?: number
  shouldOverwriteManualFees?: boolean
  manualFeeDefinitionsArgs?: ManualFeeDefinitionArgs[]
  durationInDays?: number
}): DealCalculationQueryVariables => {
  return {
    companyId,
    dealType,
    durationInDays:
      durationInDays && dealType === EDealType.Pawn ? durationInDays : 0,
    date,
    dealItemsCalculationArgs: items.map((item) => {
      return {
        answers: item.answers.map((answer) => parseAnswer(answer)),
        algoliaReference: item.algoliaReference,
        customPayoutAmount: item.customPayoutAmount,
        itemCategoryId: item.itemCategoryId,
        material: item.material,
        isInvestmentGold: item.isInvestmentGold,
        existingCurrentMarketValue: item.existingCurrentMarketValue,
        existingPredictedMarketValue: item.existingPredictedMarketValue,
        useOldValuesOfPrediction: item.useOldValuesOfPrediction,
        variantId: item.variantId,
        unconfirmedPropertyIds: item.unconfirmedPropertyIds,
      }
    }),
    pickupTransportMode: pickupTransportMode ?? ETransportMode.Shop,
    dropoffTransportMode: dropoffTransportMode ?? ETransportMode.Shop,
    shouldOverwritePayoutAmount,
    overwrittenPayoutAmount,
    shouldOverwriteManualFees,
    manualFeeDefinitionsArgs,
    isReversedCalculation: false,
  }
}

// parse the selected option before sending, as parsing in the onChange seems insufficient
function parseAnswer(answer: ItemAnswerArgs) {
  if (typeof answer.selectedOptionIndex === 'string') {
    return {
      ...answer,
      selectedOptionIndex: parseInt(answer.selectedOptionIndex),
    }
  }

  return answer
}

export const useCreateDeal = () => {
  const [createDeal, createDealRes] = useMutation<
    CreateDealMutation,
    CreateDealMutationVariables
  >(CreateDealDocument)
  const setDealId = useSetRecoilState(dealIdState)

  const dealId = createDealRes.data?.createDeal._id

  if (dealId) {
    setDealId(dealId)
  }

  return {
    createDeal,
    result: createDealRes,
  }
}

export const useCreateDealForCustomer = () => {
  const [createDealForCustomer, createDealForCustomerRes] = useMutation<
    CreateDealForCustomerMutation,
    CreateDealForCustomerMutationVariables
  >(CreateDealForCustomerDocument)
  const setDealId = useSetRecoilState(dealIdState)

  const dealId = createDealForCustomerRes.data?.createDealForCustomer._id

  if (dealId) {
    setDealId(dealId)
  }

  return {
    createDealForCustomer,
    result: createDealForCustomerRes,
  }
}

export async function useCheckCheckoutDate() {
  const [checkoutDate] = useRecoilState(checkoutDateState)
  const dealType = useRecoilValue(dealTypeState)
  const selectedItems = useRecoilValue(selectedItemsState)
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const { t } = useTranslation()

  const recalculateCheckoutState = useRecalculateCheckoutState()
  const { showWarning } = useShowSnackbar()

  const isCartInvalid = useCheckCartInvalid()

  const company = useCompany()

  const [getDealCalculation] = useLazyQuery<
    DealCalculationQuery,
    DealCalculationQueryVariables
  >(DealCalculationDocument)

  if (checkoutDate) {
    const now = dayjs()
    const diff = now.diff(dayjs(checkoutDate), 'minute')

    if (diff >= config.checkoutExpirationMinutes && !loading) {
      setLoading(true)
      if (!company) return
      try {
        const dealCalculationResult = await getDealCalculation({
          variables: buildDealCalculationVariables({
            companyId: company._id,
            dealType,
            date: now.toDate(),
            items: isCartInvalid ? [] : selectedItems,
            durationInDays: company.configuration.minimumPawnDuration,
          }),
        })

        if (dealCalculationResult.data?.dealCalculation) {
          if (selectedItems.length > 0) {
            recalculateCheckoutState({
              checkoutDate: now.toDate(),
              dealCalculation: dealCalculationResult.data.dealCalculation,
            })

            showWarning(t('common:session_expired'), { autoHideDuration: 7500 })

            if (router.pathname.startsWith('/checkout')) {
              router.replace('/checkout/items')
            }
          } else {
            recalculateCheckoutState({
              checkoutDate: now.toDate(),
              dealCalculation: dealCalculationResult.data.dealCalculation,
            })
          }
        }
      } finally {
        setLoading(false)
      }
    }
  }
}

function useRecalculateCheckoutState() {
  const setCheckoutDate = useSetRecoilState(checkoutDateState)
  const setDealCalculation = useSetRecoilState(dealCalculationState)

  const setTransportData = useSetRecoilState(transportDataState)
  const setPaymentData = useSetRecoilState(paymentDataState)
  const setDealId = useSetRecoilState(dealIdState)
  const setFeedback = useSetRecoilState(feedbackState)

  const recalculateCheckoutStateFunc = useCallback(
    (params: {
      checkoutDate: Date
      dealCalculation: PersistedDealCalculation
    }) => {
      setCheckoutDate(params.checkoutDate)
      setDealCalculation(params.dealCalculation)
      setTransportData(undefined)
      setPaymentData(undefined)
      setDealId('')
      setFeedback(0)
    },
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return recalculateCheckoutStateFunc
}

/**
 * This function checks whether the current checkout is a simplified pawn checkout or not,
 * and ensures that common events are setting the state correctly.

 * __Important__: This uses the `routeChangeComplete` event on NextJS's router, so this hook _must_ be used in the page itself,
 * not in a component.
 */
export function useIsSimplifiedPawnCheckout(): boolean {
  const router = useRouter()
  const [dealType, setDealType] = useRecoilState(dealTypeState)
  const [isSimplifiedPawnCheckout, setIsSimplifiedPawnCheckout] =
    useRecoilState(isSimplifiedPawnCheckoutState)

  if (isSimplifiedPawnCheckout && dealType !== EDealType.Pawn) {
    setIsSimplifiedPawnCheckout(false)
  }

  const handleNavigation = useRecoilCallback(
    ({ snapshot }) =>
      () => {
        const selectedItemsCount =
          snapshot.getLoadable(selectedItemsState).valueMaybe()?.length ?? 0
        const dealType =
          snapshot.getLoadable(dealTypeState).valueMaybe() ?? EDealType.Pawn
        if (dealType === EDealType.Pawn || selectedItemsCount === 0) {
          setDealType(EDealType.Pawn)
          setIsSimplifiedPawnCheckout(true)
        }
      },
    [],
  )

  useEffect(() => {
    handleNavigation()
  }, [handleNavigation])

  useEffect(() => {
    router.events.on('routeChangeComplete', handleNavigation)
    return () => {
      router.events.off('routeChangeComplete', handleNavigation)
    }
  }, [handleNavigation, router.events])

  return isSimplifiedPawnCheckout
}

export function useResetCheckoutState() {
  const resetTransportData = useResetRecoilState(transportDataState)
  const resetPaymentData = useResetRecoilState(paymentDataState)
  const resetFeedback = useResetRecoilState(feedbackState)
  const resetCheckoutDate = useResetRecoilState(checkoutDateState)
  const resetDealCalculation = useResetRecoilState(dealCalculationState)
  const resetSelectedItems = useResetRecoilState(selectedItemsState)
  const resetDealType = useResetRecoilState(dealTypeState)
  const resetTransportFormState = useResetRecoilState(
    transportFormStateCheckout,
  )
  const resetKnpDebtState = useResetRecoilState(knpDebtState)
  const resetIsCustomDealPayoutOverwrittenState = useResetRecoilState(
    isCustomDealPayoutOverwrittenState,
  )
  const resetIsSimplifiedPawnCheckoutState = useResetRecoilState(
    isSimplifiedPawnCheckoutState,
  )
  const resetGuestEmailState = useResetRecoilState(guestEmailState)

  return useCallback(() => {
    resetCheckoutDate()
    resetSelectedItems()
    resetDealCalculation()
    resetTransportData()
    resetPaymentData()
    resetFeedback()
    resetDealType()
    resetIsSimplifiedPawnCheckoutState()
    resetTransportFormState()
    resetKnpDebtState()
    resetIsCustomDealPayoutOverwrittenState()
    resetGuestEmailState()
    tracking.user(null)
  }, [
    resetCheckoutDate,
    resetDealCalculation,
    resetDealType,
    resetIsSimplifiedPawnCheckoutState,
    resetFeedback,
    resetIsCustomDealPayoutOverwrittenState,
    resetKnpDebtState,
    resetPaymentData,
    resetSelectedItems,
    resetTransportData,
    resetTransportFormState,
    resetGuestEmailState,
  ])
}

export function getKnpDebtInfo(opts: {
  payoutAmount: number
  debtAmount: number
}) {
  let cashyPayout = 0
  let debtPayout = 0

  const debtAmount = opts.debtAmount
  if (debtAmount > opts.payoutAmount) {
    debtPayout = opts.payoutAmount
    cashyPayout = 0
  } else {
    debtPayout = debtAmount
    cashyPayout = opts.payoutAmount - debtPayout
  }

  return {
    cashyPayout,
    debtPayout,
    remainingDebt: opts.debtAmount - debtPayout,
  }
}

// checks whether the cart contains invalid items
// which existed in the cart before the category migration took place and thus contain invalid data.
export function useCheckCartInvalid() {
  const [selectedItems] = useRecoilState(selectedItemsState)

  const itemCategoriesRes = useQuery<
    GetItemCategoriesQuery,
    GetItemCategoriesQueryVariables
  >(GetItemCategoriesDocument, {
    enableCaching: true,
  })

  const itemCategories = itemCategoriesRes.data?.getItemCategories

  for (const item of selectedItems) {
    // 1st case: item has no categoryId (old item)
    if (!item.itemCategoryId) {
      return true
    }

    // 2nd case: item categoryId is invalid
    if (
      itemCategories &&
      !itemCategories
        ?.map((category) => category._id)
        .includes(item.itemCategoryId)
    ) {
      return true
    }

    //3rd case: item is not custom deal and item itemCategory is invalid
    if (!item.customDealId && !item.itemCategory) {
      return true
    }
  }

  return false
}

export function useDealCalculation() {
  const [, setDealCalculation] = useRecoilState(dealCalculationState)
  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)
  const [transportForm] = useRecoilState(transportFormStateCheckout)
  const [durationInDays] = useRecoilState(dealDurationInDaysState)
  const transportMode = transportForm?.transportMode
  const [dealType] = useRecoilState(dealTypeState)
  const checkoutDate = useRecoilValue(checkoutDateState)
  const isCustomDealPayoutOverwritten = useRecoilValue(
    isCustomDealPayoutOverwrittenState,
  )

  const company = useCompany()

  const isCartInvalid = useCheckCartInvalid()
  if (isCartInvalid) {
    // TODO: CQI-2 fix this violation of react-hooks/rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<DealCalculationQuery, DealCalculationQueryVariables>(
      DealCalculationDocument,
      {
        variables: {
          ...buildDealCalculationVariables({
            companyId: company?._id,
            dealType,
            date: checkoutDate,
            items: [],
            pickupTransportMode: transportMode ?? undefined,
            durationInDays,
            shouldOverwritePayoutAmount: isCustomDealPayoutOverwritten,
            overwrittenPayoutAmount: isCustomDealPayoutOverwritten
              ? selectedItems?.reduce(
                  (total, item) => total + (item.customPayoutAmount ?? 0),
                  0,
                )
              : 0,
          }),
          customDealId: selectedItems.find((c) => c.customDealId)?.customDealId,
        },
        skip: !company || !durationInDays,
        onError: () => {
          setDealCalculation(undefined)
        },
        onCompleted: (data) => {
          if (data) {
            setDealCalculation(data.dealCalculation)
            setSelectedItems([]) // This should not happen, but just in case there are some "dirty" data before the migration
          }
        },
      },
    )
  } else {
    // TODO: CQI-2 fix this violation of react-hooks/rules-of-hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useQuery<DealCalculationQuery, DealCalculationQueryVariables>(
      DealCalculationDocument,
      {
        variables: {
          ...buildDealCalculationVariables({
            companyId: company?._id,
            dealType,
            date: checkoutDate,
            items: selectedItems ?? [],
            pickupTransportMode: transportMode ?? undefined,
            durationInDays,
            shouldOverwritePayoutAmount: isCustomDealPayoutOverwritten,
            overwrittenPayoutAmount: isCustomDealPayoutOverwritten
              ? selectedItems?.reduce(
                  (total, item) => total + (item.customPayoutAmount ?? 0),
                  0,
                )
              : 0,
          }),
          customDealId: selectedItems.find((c) => c.customDealId)?.customDealId,
        },
        skip: !company?.configuration.minimumPawnDuration || !durationInDays,
        onError: () => {
          setDealCalculation(undefined)
        },
        onCompleted: (data) => {
          if (data) {
            setDealCalculation(data.dealCalculation)
          }
        },
      },
    )
  }
}

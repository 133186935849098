import h1 from './h1'
import h2 from './h2'
import h3 from './h3'
import modify from './modify'
import p from './p'
import Sizes from './sizes'
import spacer from './spacer'

const components = {
  ...Sizes,
  h1,
  h2,
  h3,
  p,
  spacer,
  modify,
}

export default components

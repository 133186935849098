import AccountIcon from '../../../assets/ico_account.svg'
import LoggedInAccountIcon from '../../../assets/ico_account_logged_in.svg'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import { useContext, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { useLogoutCustomer } from '@/app/auth/auth.service'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  GridItem,
} from '@/app/common/components/Header/components'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'

const headerPrefix = 'header'

export default function AccountButton() {
  const { t } = useTranslation()

  const [customer] = useRecoilState(customerState)
  const modal = useContext(ModalDialogContext)

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  useEffect(() => {
    setIsLoggedIn(!!customer)
  }, [customer])

  const handleLoginClick = () => {
    modal.open(<Login />, { variant: 'full' })
  }

  const logoutCustomer = useLogoutCustomer()

  return (
    <>
      <GridItem zIndex={1} area="account">
        {isLoggedIn ? (
          <Dropdown className="h-full">
            <Link
              className="h-full size-10 flex justify-center items-center"
              id={`${headerPrefix}_profile`}
              href={'/profile'}
            >
              <LoggedInAccountIcon className="size-5 md:size-6 stroke-[2.5px]" />
            </Link>

            <DropdownMenu className="-right-3">
              <Link id={`${headerPrefix}_profile`} href={'/profile'}>
                <DropdownItem>{t('common:header.profile')}</DropdownItem>
              </Link>

              <DropdownItem onClick={logoutCustomer}>
                {t('common:auth.logout')}
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <button
            className="size-full flex justify-center items-center"
            onClick={handleLoginClick}
          >
            <span className="h-full size-10 flex justify-center items-center">
              <AccountIcon className="size-5 md:size-6 stroke-[2.5px]" />
            </span>
          </button>
        )}
      </GridItem>
    </>
  )
}

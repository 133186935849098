import { storyblokEditable } from '@storyblok/react'
import React, {
  FunctionComponent,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Row } from 'react-styled-flexboxgrid'
import styled from 'styled-components'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import TrustedMediaCarousel, {
  trustedMediaHeight,
} from '@/domains/marketing/components/TrustedMedia/TrustedMediaCarousel'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { getFontCSS } from '@/style/components/LegacyText/sizes'
import { SectionInner } from '@/style/components/Section'
import { media } from '@/style/helpers'
import { HeroStoryblok } from '@/types/storyblok-component-types'
import { HeroSectionWrapper } from './HeroSectionWrapper'
import PromotionBadge from './PromotionBadge'

const Hero: FunctionComponent<{
  blok: HeroStoryblok
}> = ({ blok }) => {
  const richTextRef = useRef<HTMLDivElement>(null)
  const [richTextHeight, setRichTextHeight] = useState(0)

  const link = getStoryBlokLink(blok.upper_right_badge_link)
  const isLink = !!(link || blok.upper_right_badge_link?.id)
  const isExternalLink = blok.upper_right_badge_link?.linktype === 'asset'

  // destructuring needed for TS interference
  const { trusted_media_list: trustedMedia } = blok
  const isTrustedMediaActive =
    !!trustedMedia && trustedMedia?.some((media) => media.image.id)

  useLayoutEffect(() => {
    const updateHeight = () => {
      if (richTextRef.current) {
        setRichTextHeight(richTextRef.current.clientHeight + 205)
      }
    }

    updateHeight()
    window.addEventListener('resize', updateHeight)

    return () => window.removeEventListener('resize', updateHeight)
  }, [])

  const imageLoader = useStoryblokImageLoader({})

  const desktopSrcSet = useMemo(() => {
    const src = blok.hero_image?.filename ?? blok.hero_image_mobile?.filename

    return desktopScreenWidths
      .map((width) => `${imageLoader({ src, width, quality: 80 })} ${width}w`)
      .join(', ')
  }, [imageLoader, blok.hero_image?.filename, blok.hero_image_mobile?.filename])

  const mobileSrcSet = useMemo(() => {
    const src = blok.hero_image_mobile?.filename

    return mobileScreenWidths
      .map((width) => `${imageLoader({ src, width, quality: 80 })} ${width}w`)
      .join(', ')
  }, [imageLoader, blok.hero_image_mobile?.filename])

  return (
    <HeroSectionWrapper
      sizing="xlarge"
      backgroundColor="primary"
      color="white"
      style={{
        backgroundColor: blok.background_color?.color,
      }}
      {...storyblokEditable(blok)}
      $richTextHeight={richTextHeight}
      $isTrustedMediaActive={isTrustedMediaActive}
      $mobileMinHeight={Number(blok.min_height_mobile)}
      $mobileMaxHeight={Number(blok.max_height_mobile)}
    >
      <HeroImageWrapper $isTrustedMediaActive={isTrustedMediaActive}>
        {blok.hero_image?.id && (
          <StyledPicture
            key={blok.hero_image.id}
            $richTextHeight={richTextHeight}
            $isTrustedMediaActive={isTrustedMediaActive}
          >
            <source media="(min-width: 992px)" srcSet={desktopSrcSet} />
            <source media="(max-width: 991px)" srcSet={mobileSrcSet} />

            <StyledImage
              src={imageLoader({
                src: blok.hero_image_mobile?.filename,
                width: 1920,
              })}
              alt={blok.hero_image.alt}
            />
          </StyledPicture>
        )}
      </HeroImageWrapper>

      <PromotionBadge
        image={blok.upper_right_badge}
        link={link}
        isLink={isLink}
        isExternalLink={isExternalLink}
      />

      <SectionInner sizing="wide" style={{ width: '100%', height: '100%' }}>
        <CenteredRow
          $isTrustedMediaActive={isTrustedMediaActive}
          $richTextHeight={richTextHeight}
        >
          <RichTextParagraphStyles>
            <CustomRichTextHero ref={richTextRef}>
              <StoryblokRichText document={blok.content} />
            </CustomRichTextHero>
          </RichTextParagraphStyles>
        </CenteredRow>
      </SectionInner>
      {isTrustedMediaActive && (
        <TrustedMediaCarousel
          mediaLinks={trustedMedia.filter((media) => media.image.id)}
        />
      )}
    </HeroSectionWrapper>
  )
}

export default Hero

const desktopScreenWidths = [1200, 1920, 2048, 3840]
const mobileScreenWidths = [360, 640, 750, 828, 1080, 1200, 1920]

const CenteredRow = styled(Row)<{
  $isTrustedMediaActive: boolean
  $richTextHeight: number
}>`
  align-items: flex-end;
  text-align: left;
  width: 100%;
  height: ${(props) =>
    props.$isTrustedMediaActive
      ? `calc(100% - ${trustedMediaHeight.xs}px)`
      : '100%'};
  padding-bottom: 7rem;
  margin: 0rem;

  ${media.md} {
    flex-direction: row;
    align-items: center;
    max-width: 37.5rem;
    padding-bottom: 0;
  }
  span {
    width: 100%;
  }

  @media (max-height: ${(props) => props.$richTextHeight}px) {
    min-height: ${(props) =>
      props.$richTextHeight && props.$richTextHeight - (205 + 65)}px;
  }
`

const CustomRichTextHero = styled.div`
  width: 100%;
  ${media.md} {
    width: auto;
  }
  ${media.xs} {
    width: 90%;
  }

  h1 {
    line-height: 2.45rem;
    font-size: 1.75rem;

    ${media.md} {
      line-height: 3.3125rem;
      font-size: 2.1875rem;
    }

    ${media.xs} {
      ${getFontCSS('xxxl')}
      font-weight: 700;
    }
  }

  ul {
    margin: 1rem auto;
    list-style: none;
    padding: 0;
  }

  li {
    padding-bottom: 0.45rem;
    padding-top: 0.45rem;
    display: flex;
    p {
      margin: 0 !important;
      display: inline-block;
      font-size: inherit;
      line-height: 1;
    }
  }

  li:before {
    content: '✓';
    display: inline-block;
    margin-left: 1rem;
    margin-right: 0.25rem;
    color: rgb(1, 255, 108);
  }

  h2 {
    font-weight: normal !important;
    margin-bottom: 1.5rem;
    text-transform: none;
  }

  p {
    font-size: 1rem;
    line-height: 1.1;
  }

  .cta-button {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cta-button:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`

const HeroImageWrapper = styled.div<{
  $isTrustedMediaActive: boolean
}>`
  height: ${(props) =>
    props.$isTrustedMediaActive
      ? `calc(100% - ${trustedMediaHeight.upToXs}px)`
      : '100%'};

  max-height: 1000px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  ${media.xs} {
    height: ${(props) =>
      props.$isTrustedMediaActive
        ? `calc(100% - ${trustedMediaHeight.xs}px)`
        : '100%'};
  }
`

const StyledPicture = styled.picture<{
  $isTrustedMediaActive: boolean
  $richTextHeight: number
}>`
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  @media (max-height: ${(props) => props.$richTextHeight}px) {
    min-height: ${(props) =>
      props.$richTextHeight && props.$richTextHeight - trustedMediaHeight.xs}px;
  }

  @media (max-height: ${(props) =>
      props.$richTextHeight}px) and (max-width: 479px) {
    min-height: ${(props) =>
      props.$richTextHeight &&
      props.$richTextHeight - trustedMediaHeight.upToXs}px;
  }
`

const StyledImage = styled.img`
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-position: center top;
`

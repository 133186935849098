import NextStoryblokImage from '../../StoryblokImage/NextStoryblokImage'
import ArrowSmall from '../assets/arrow_small.svg'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { MenuItemV2Storyblok } from '@/types/storyblok-component-types'

export default function MenuItem({ blok }: { blok: MenuItemV2Storyblok }) {
  return (
    <Link
      {...storyblokEditable(blok)}
      href={getStoryBlokLink(blok.link)}
      className="bg-[#F3F3F3] rounded-xl flex flex-row p-4 gap-2 text-sm items-center font-medium"
    >
      {blok.icon && (
        <NextStoryblokImage
          image={blok.icon}
          width={24}
          height={24}
          className="size-6 object-contain"
        />
      )}
      {blok.title}
      <ArrowSmall className="text-black ml-auto" />
    </Link>
  )
}

import NextStoryblokImage from '../../StoryblokImage/NextStoryblokImage'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { FeaturedMenuItemStoryblok } from '@/types/storyblok-component-types'

export default function FeaturedMenuItem({
  blok,
}: {
  blok: FeaturedMenuItemStoryblok
}) {
  return (
    <Link
      {...storyblokEditable(blok)}
      className="bg-[#F3F3F3] rounded-xl flex flex-row"
      href={getStoryBlokLink(blok.link)}
    >
      <NextStoryblokImage
        image={blok.image}
        width={89}
        height={80}
        focal
        className="h-20 w-[89px] object-cover"
      />

      <div className="flex flex-col px-2 py-4">
        <span className="text-sm font-semibold">{blok.title}</span>
        <span className="text-xs text-[#272727]">{blok.caption}</span>
      </div>
    </Link>
  )
}

import { Slot } from '@radix-ui/react-slot'
import { VariantProps, cva } from 'class-variance-authority'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import React, { useContext } from 'react'
import { useLogoutCustomer } from '@/app/auth/auth.service'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import Register from '@/app/auth/components/Register'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import { cn } from '@/utils/cn'

interface MenuFooterProps {
  closeMenu: () => void
}

export function MenuFooter({ closeMenu }: MenuFooterProps) {
  const { t } = useTranslation()
  const modal = useContext(ModalDialogContext)
  const [user] = useRecoilStateWithSSRFallback(customerState, undefined)
  const logoutCustomer = useLogoutCustomer()

  if (user) {
    return (
      <>
        <Button variant="secondary" asChild>
          <Link href="/profile/edit">{t('common:auth.account_settings')}</Link>
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            logoutCustomer()
            closeMenu()
          }}
        >
          Logout
        </Button>
      </>
    )
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          closeMenu()
          modal.open(<Login />, { variant: 'full' })
        }}
      >
        {t('common:auth.login')}
      </Button>
      <Button
        onClick={() => {
          closeMenu()
          modal.open(<Register />, { variant: 'full' })
        }}
      >
        {t('common:auth.register')}
      </Button>
    </>
  )
}

const actionLinkVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium px-4 py-2',
  {
    variants: {
      variant: {
        default: 'bg-black text-white rounded-md',
        secondary: 'bg-white border-2 border-black rounded-md',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof actionLinkVariants> {
  asChild?: boolean
}

function Button({
  className,
  variant,
  asChild = false,
  ...props
}: ButtonProps) {
  const Comp = asChild ? Slot : 'button'
  return (
    <Comp
      className={cn(actionLinkVariants({ variant, className }))}
      {...props}
    />
  )
}

import { atom } from 'recoil'
import { TransportFormState } from '@/app/common/common.state'
import {
  CreditcardPaymentArgs,
  EAutomaticPaymentType,
  EManualPaymentType,
  EpsPaymentArgs,
  TransportData,
} from '@/types/gql/graphql'

export const paybackPayoutTypeState = atom<
  EManualPaymentType | EAutomaticPaymentType | undefined
>({
  key: 'profile:paybackPayout',
  default: undefined,
})

export const extensionPayoutTypeState = atom<
  EManualPaymentType | EAutomaticPaymentType | undefined
>({
  key: 'profile:extensionPayout',
  default: undefined,
})

export const paybackTransportDataState = atom<TransportData | undefined>({
  key: 'profile:paybackTransportDataState',
  default: undefined,
})

export const paybackShopIdState = atom<string | undefined>({
  key: 'profile:paybackShopId',
  default: undefined,
})

export const successSentenceState = atom<
  | {
      paymentType: EManualPaymentType | EAutomaticPaymentType
      paybackAmount: number
      successType: string
    }
  | undefined
>({
  key: 'profile:successSentence',
  default: undefined,
})

export const transportFormStateProfile = atom<TransportFormState | undefined>({
  key: 'profile:transportForm',
  default: undefined,
})

export const onlinePaymentMethodState = atom<
  CreditcardPaymentArgs | EpsPaymentArgs | undefined
>({
  key: 'profile:onlinePaymentMethod',
  default: undefined,
})

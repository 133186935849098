import CustomDealSuccessModal from '../CustomDealForm/CustomDealSuccessModal'
import { useCreateCustomDeal } from '../CustomDealForm/useCreateCustomDeal'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-styled-flexboxgrid'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'
import { customerState } from '@/app/auth/auth.state'
import { ModalDialogContext } from '@/app/common/context/modalDialogContext'
import { useLazyQuery } from '@/app/common/graphql/hooks'
import {
  buildDealCalculationVariables,
  useCheckCartInvalid,
} from '@/domains/checkout/checkout.service'
import {
  dealCalculationState,
  selectedItemsState,
} from '@/domains/checkout/checkout.state'
import { DifferentInterestItemModal } from '@/domains/items/components/DifferentInterestItemModal'
import ConfiguratorHeadline from '@/domains/product/components/ProductConfigurator/ConfiguratorHeadline/ConfiguratorHeadline'
import ConfiguratorProperty, {
  OptionsProps,
} from '@/domains/product/components/ProductConfigurator/ConfiguratorProperty/ConfiguratorProperty'
import ConfiguratorQuestion from '@/domains/product/components/ProductConfigurator/ConfiguratorQuestion/ConfiguratorQuestion'
import ConfiguratorSummary from '@/domains/product/components/ProductConfigurator/ConfiguratorSummary/ConfiguratorSummary'
import product, { SelectedProductProps } from '@/helpers/product'
import { resizedProductImageLoader } from '@/helpers/resizedProductImageLoader'
import * as tracking from '@/helpers/tracking'
import useCompany from '@/helpers/useCompany'
import useResponsive_CAN_CAUSE_CLS from '@/helpers/useResponsive'
import useShowApolloErrorInSnackbar from '@/helpers/useShowApolloErrorInSnackbar'
import useShowSnackbar from '@/helpers/useShowSnackbar'
import SliderInput from '@/style/components/Form/SliderInput'
import { LastFallbackImage } from '@/style/components/Image'
import { SectionInner, SectionWrapper } from '@/style/components/Section'
import { media } from '@/style/helpers'
import theme from '@/style/themes/default'
import { SelectedItemsType, SelectedProduct } from '@/types'
import {
  AvailablePropertiesFragmentFragment,
  CustomDealCreateArgs,
  CustomDealItemCreateArgs,
  DealCalculationDocument,
  DealCalculationQuery,
  DealCalculationQueryVariables,
  EDealType,
  EQuestionType,
  ItemAnswer,
  ItemQuestion,
  VariantsFragmentFragment,
} from '@/types/gql/graphql'
import ContactDataModal from './ContactDataModal'
import bgImgLeft from './assets/bg_left.png'
import bgImgRight from './assets/bg_right.png'

interface ProductConfiguratorProps {
  selectedProduct: SelectedProduct
  checkoutDate?: Date | undefined
  itemQuestions?: Omit<ItemQuestion, 'validFrom' | 'validTo'>[] | undefined
  immutableAnswers?: ItemAnswer[]
  availableProperties: AvailablePropertiesFragmentFragment[] | undefined | null
  variants?: VariantsFragmentFragment[]
}

const ProductConfigurator = ({
  selectedProduct,
  checkoutDate,
  itemQuestions,
  immutableAnswers,
  availableProperties,
  variants,
}: ProductConfiguratorProps) => {
  const { name, categoryId, image, imageMode, category, price } =
    selectedProduct
  const { t } = useTranslation()
  const showErrorInSnackbar = useShowApolloErrorInSnackbar()
  const [itemAnswers, setItemAnswers] = useState<ItemAnswer[]>([])
  const [selectedItems, setSelectedItems] = useRecoilState(selectedItemsState)
  const setDealCalculation = useSetRecoilState(dealCalculationState)
  const router = useRouter()
  const modal = useContext(ModalDialogContext)
  const isCartInvalid = useCheckCartInvalid()
  const company = useCompany()
  const [customer] = useRecoilState(customerState)
  const { isDesktop } = useResponsive_CAN_CAUSE_CLS()

  const { showInfo } = useShowSnackbar()
  const categories = category.parentCategories.map((category) => category.name)

  const [selectedProperties, setSelectedProperties] = useState<
    SelectedProductProps[]
  >([])

  const getSelectedOptionIndex = (
    question: Omit<ItemQuestion, 'validFrom' | 'validTo'>,
  ) => {
    return itemAnswers?.find((answer) => answer.questionId === question._id)
      ?.selectedOptionIndex
  }

  const getRangeValue = (
    question: Omit<ItemQuestion, 'validFrom' | 'validTo'>,
  ) => {
    return itemAnswers?.find((answer) => answer.questionId === question._id)
      ?.rangeValue
  }

  const getQuestionDefaultValue = (
    question: Omit<ItemQuestion, 'validFrom' | 'validTo'>,
  ) => {
    switch (question.questionType) {
      case EQuestionType.Range:
        return question.rangeData?.defaultValue ?? 0
      case EQuestionType.SingleChoice:
        return question.singleChoiceOptions?.findIndex(
          (option) => option.isDefaultValue,
        )
    }
  }

  const [getDealCalculation] = useLazyQuery<
    DealCalculationQuery,
    DealCalculationQueryVariables
  >(DealCalculationDocument)

  const { createCustomDeal } = useCreateCustomDeal()

  const onSubmit = async () => {
    const itemCategory = category

    if (!itemCategory || isCartInvalid) {
      setSelectedItems([]) // This should not happen, but just in case there are some "dirty" data before the migration
    }

    const variant = product.findVariantByProperties(
      variants ?? [],
      selectedProperties,
      categories,
    )
    const updatedItems = !variant?.isPriceValid
      ? []
      : isCartInvalid
        ? []
        : [...(selectedItems ?? [])]

    updatedItems.push({
      title: name,
      answers: itemAnswers,
      variantId: variant?.id,
      unconfirmedPropertyIds: availableProperties
        ?.filter((property) => !property.propertyDefinition.showOnWebsite)
        .map((property) => property.propertyDefinitionId),
      itemCategoryId: categoryId,
      itemCategory: category,
      selectedVariant: product.findVariantByProperties(
        variants ?? [],
        selectedProperties,
        categories,
      ),
    })

    if (!company) return

    if (!variant?.isPriceValid) {
      const customDealCreateArgs: CustomDealCreateArgs = {
        companyId: company._id,
        items: [getCustomDealItemArgs(updatedItems[0], variant)],
        contactData: {},
        customerId: customer?._id,
        dealType: EDealType.Pawn,
        durationInDays: company.configuration.minimumPawnDuration,
        isItemEvaluationMissing: true,
      }

      if (!customer) {
        modal.open(<ContactDataModal args={customDealCreateArgs} />, {
          variant: 'full',
        })
      } else {
        createCustomDeal({
          variables: {
            customDealCreateArgs,
          },
        }).then((result) => {
          if (result.data) {
            modal.open(<CustomDealSuccessModal />, {
              variant: 'full',
              onAfterClose: () => {
                router.push('/')
              },
            })
          }
        })
      }
      return
    }

    const queryVariables = buildDealCalculationVariables({
      companyId: company._id,
      dealType: EDealType.Pawn,
      date: checkoutDate,
      items: updatedItems,
      durationInDays: company.configuration.minimumPawnDuration,
    })

    const result = await getDealCalculation({
      variables: queryVariables,
      onError(error) {
        showErrorInSnackbar(error)
        modal.open(<DifferentInterestItemModal />, {
          variant: 'full',
        })
      },
    })

    setSelectedItems(updatedItems)
    if (result.data?.dealCalculation) {
      setDealCalculation(result.data.dealCalculation)
      const { itemsValuesEntries } = result.data.dealCalculation

      tracking.addToCart([
        {
          ...selectedProduct,
          actualValue:
            itemsValuesEntries[itemsValuesEntries.length - 1]?.loanToValue,
        },
      ])

      router.push('/checkout/items')
    }
  }

  useEffect(() => {
    if (immutableAnswers?.length === 0) {
      setItemAnswers(product.buildItemAnswers(itemQuestions))
    } else {
      const defaultAnswers = product.buildItemAnswers(itemQuestions)
      const itemAnswers = defaultAnswers.map(
        (answer) =>
          immutableAnswers?.find(
            (immutableAnswer) =>
              immutableAnswer.questionId === answer.questionId,
          ) ?? answer,
      )

      setItemAnswers(itemAnswers)
    }
  }, [itemQuestions, immutableAnswers])

  useEffect(() => {
    setSelectedProperties(
      product.findDefaultProperties(variants, router.locale) ?? [],
    )
  }, [variants, router])

  const skipItemCalculation =
    Boolean(itemQuestions?.length) && !Boolean(itemAnswers?.length)

  const selectedProductImage = product.getSelectedProductImage({
    selectedProperties,
    variants: variants ?? [],
    image,
    imageMode,
  })

  return (
    <RowStyled>
      <LeftCol xs={12} md={8}>
        <StyleSectionWrapper
          backgroundColor={isDesktop ? 'buttonBorder' : 'white'}
        >
          <SectionInner sizing="narrow">
            <ConfiguratorHeadline
              image={
                selectedProduct &&
                selectedProductImage &&
                typeof selectedProductImage.url === 'string' ? (
                  <Image
                    loader={resizedProductImageLoader}
                    fill
                    sizes={`(max-width: ${theme.breakpoints.xs}px) 50vw, (max-width: ${theme.breakpoints.md}px) 240px, (max-width: ${theme.breakpoints.lg}px) 15.33vw, 184px`}
                    src={selectedProductImage.url}
                    alt={selectedProduct.name}
                  />
                ) : (
                  <LastFallbackImage />
                )
              }
              categoryHeadline={t('product:your_item')}
              productHeadline={name}
            />
          </SectionInner>

          <SectionInner sizing="narrow">
            <ProductOptionsContainer>
              {availableProperties &&
                product
                  .formatAvailableProperties(
                    availableProperties.filter(
                      (property) => property.propertyDefinition.showOnWebsite,
                    ),
                    t,
                    router.locale,
                  )
                  .map((property) => {
                    return (
                      <ConfiguratorProperty
                        buttonStyle={{ margin: '0.25rem' }}
                        headline={`${property?.title}:`}
                        description={property?.description}
                        options={(property?.values ?? []).filter(
                          (value): value is OptionsProps => value !== undefined,
                        )}
                        value={
                          selectedProperties.find(
                            (sp) =>
                              sp.propertyDefinitionId ===
                              property?.propertyDefinitionId,
                          )?.value
                        }
                        onChange={(value) => {
                          const newSelectedProperties = [...selectedProperties]
                          const existingPropertyIndex =
                            newSelectedProperties.findIndex(
                              (sp) =>
                                sp.propertyDefinitionId ===
                                property?.propertyDefinitionId,
                            )
                          if (existingPropertyIndex !== -1) {
                            newSelectedProperties[existingPropertyIndex].value =
                              value
                          } else {
                            const propertyDefinitionId =
                              property.propertyDefinitionId
                            newSelectedProperties.push({
                              propertyDefinitionId,
                              value,
                            })
                          }

                          const variant = product.findValidVariant(
                            newSelectedProperties,
                            variants ?? [],
                          )

                          if (variant) {
                            setSelectedProperties(newSelectedProperties)
                          } else {
                            showInfo(
                              t(
                                'product:warning.your_selection_automatically_adjusted',
                              ),
                              {
                                autoHideDuration: 4000,
                              },
                            )

                            const adjustedProperties =
                              product.adjustToValidVariant(
                                newSelectedProperties,
                                variants ?? [],
                                property?.propertyDefinitionId,
                                value,
                                price,
                              )
                            if (adjustedProperties) {
                              setSelectedProperties(adjustedProperties)
                            } else {
                              setSelectedProperties(
                                product.findDefaultProperties(
                                  variants,
                                  router.locale,
                                ) ?? [],
                              )
                            }
                          }
                        }}
                      />
                    )
                  })}

              {itemQuestions &&
                itemQuestions.map((question) => {
                  if (
                    question.questionType === EQuestionType.SingleChoice &&
                    question.singleChoiceOptions
                  ) {
                    const isOpticalCondition =
                      question.titleKey === 'QUESTION_OPTICAL_CONDITION'
                    return (
                      <ConfiguratorQuestion
                        key={question._id}
                        slug={question?.titleKey || ''}
                        options={Object.keys(question.singleChoiceOptions).map(
                          (key) => {
                            return {
                              value: key as any,
                              name: question.singleChoiceOptions
                                ? t(
                                    `product:item_questions.${
                                      question.singleChoiceOptions[key as any]
                                        .labelKey
                                    }`,
                                  )
                                : '',
                              description:
                                question.singleChoiceOptions &&
                                question.singleChoiceOptions[key as any].infoKey
                                  ? t(
                                      `product:item_questions.${
                                        question.singleChoiceOptions[key as any]
                                          .infoKey
                                      }`,
                                    )
                                  : t(
                                      `product:item_questions.${question.infoKey}`,
                                    ),
                              slug:
                                question.singleChoiceOptions?.[key as any]
                                  .labelKey || '',
                            }
                          },
                        )}
                        value={
                          getSelectedOptionIndex(question) ??
                          getQuestionDefaultValue(question)
                        }
                        headline={`${t(`product:item_questions.${question.titleKey}`)}:`}
                        onChange={(value) => {
                          const updatedAnswers = [...itemAnswers]
                          const existingAnswerIndex = updatedAnswers.findIndex(
                            (answer) => answer.questionId === question._id,
                          )

                          if (existingAnswerIndex >= 0) {
                            updatedAnswers[existingAnswerIndex] = {
                              ...updatedAnswers[existingAnswerIndex],
                              selectedOptionIndex: parseInt(value),
                            }

                            setItemAnswers(updatedAnswers)
                          } else {
                            updatedAnswers.push({
                              questionId: question._id,
                              selectedOptionIndex: parseInt(value),
                            })
                            setItemAnswers(updatedAnswers)
                          }
                        }}
                        buttonStyle={
                          isOpticalCondition ? { textAlign: 'left' } : {}
                        }
                        nestedDescription={isOpticalCondition}
                      />
                    )
                  } else if (
                    question.questionType === EQuestionType.Range &&
                    question.rangeData
                  ) {
                    const unit = t(
                      `product:item_questions.${question.rangeData.unitKey}`,
                    )

                    return (
                      <SliderInput
                        hidden={
                          immutableAnswers?.find(
                            (answer) => answer.questionId === question._id,
                          ) !== undefined
                        }
                        key={question._id}
                        slug={question?.titleKey ?? question._id}
                        value={
                          getRangeValue(question) ??
                          getQuestionDefaultValue(question)
                        }
                        label={`${t(`product:item_questions.${question.titleKey}`)}:`}
                        min={question.rangeData.minValue}
                        max={question.rangeData.maxValue}
                        unit={unit}
                        step={unit === 'g' ? 0.1 : undefined}
                        decimalPlaces={unit === 'g' ? 3 : 0}
                        onChange={(value) => {
                          const updatedAnswers = [...itemAnswers]
                          const existingAnswerIndex = updatedAnswers.findIndex(
                            (answer) => answer.questionId === question._id,
                          )

                          if (existingAnswerIndex >= 0) {
                            updatedAnswers[existingAnswerIndex] = {
                              ...updatedAnswers[existingAnswerIndex],
                              rangeValue: value,
                            }

                            setItemAnswers(updatedAnswers)
                          } else {
                            updatedAnswers.push({
                              questionId: question._id,
                              rangeValue: value,
                            })
                            setItemAnswers(updatedAnswers)
                          }
                        }}
                      />
                    )
                  }
                })}
            </ProductOptionsContainer>
          </SectionInner>
        </StyleSectionWrapper>
      </LeftCol>
      <RightCol xs={12} md={4}>
        <ConfiguratorSummary
          product={selectedProduct}
          itemQuestions={itemQuestions}
          itemAnswers={itemAnswers}
          checkoutDate={checkoutDate}
          onSubmit={onSubmit}
          skipItemCalculation={skipItemCalculation}
          variant={product.findVariantByProperties(
            variants ?? [],
            selectedProperties,
            categories,
          )}
        />
      </RightCol>
    </RowStyled>
  )
}

const StyleSectionWrapper = styled(SectionWrapper)`
  position: relative;
  z-index: 1;
  padding-bottom: 0 !important;

  ${SectionInner} {
    overflow: hidden;

    &:not(:first-child) {
      background-color: ${({ theme }) => theme.colors.buttonBorder};

      ${media.md} {
        background-color: transparent;
      }
    }

    &:nth-of-type(2) {
      display: flex;
      justify-content: center;
    }
  }

  ${media.md} {
    padding-bottom: 2.5rem !important;

    &::after,
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      background-image: url(${bgImgLeft.src});
      width: 30%;
      height: 100%;
      background-position: bottom left;
      background-size: contain;
      background-repeat: no-repeat;
      pointer-events: none;
      z-index: -1;
    }

    &::after {
      left: auto;
      right: 0;
      background-image: url(${bgImgRight.src});
    }
  }
`
const ProductOptionsContainer = styled.div`
  max-width: 28.125rem;
  width: 100%;
`

const RowStyled = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`

const LeftCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`

const RightCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`

export default ProductConfigurator

const getCustomDealItemArgs = (
  item: SelectedItemsType,
  variant?: VariantsFragmentFragment,
): CustomDealItemCreateArgs => {
  return {
    itemCategoryId: item.itemCategoryId ?? '',
    title: variant?.name ?? item.title,
    mediaUploads: variant?.image
      ? [
          {
            url: variant.image.url,
          },
        ]
      : [],
    answers: item.answers,
    pawnPayoutAmount: 0,
    purchasePayoutAmount: 0,
    variantId: variant?.id,
    unconfirmedPropertyIds: item.unconfirmedPropertyIds,
  }
}

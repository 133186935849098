import NextStoryblokImage, {
  extractAspectRatio,
} from '@/app/common/components/StoryblokImage/NextStoryblokImage'
import { FullWidthImageStoryblok } from '@/types/storyblok-component-types'

export interface FullWidthImageProps {
  blok: FullWidthImageStoryblok
}

export default function FullWidthImage({ blok }: FullWidthImageProps) {
  return (
    <div
      className="relative w-full"
      style={{
        aspectRatio: extractAspectRatio(blok.image),
        maxHeight: blok.max_height && `${blok.max_height}px`,
      }}
    >
      <NextStoryblokImage
        image={blok.image}
        fill
        style={{
          objectFit: blok.scaling || 'contain',
          objectPosition: blok.position || 'center',
        }}
      />
    </div>
  )
}

import {
  ContentCategories,
  DealEvent,
  DealItemCreateArgs,
  FileMetadata,
  GetItemPageContentQuery,
  GetProductBySlugQuery,
  ItemCategory,
  Maybe,
  UploadFile,
  VariantsFragmentFragment,
} from './gql/graphql'

export enum ShipmentTypes {
  PICKUP = 'PICKUP',
  SHOP = 'SHOP',
  POST = 'POST',
  PREMIUM = 'PREMIUM',
}

type BaseProduct = GetProductBySlugQuery['getProductBySlug']
export interface SelectedProduct extends BaseProduct {
  // customMarketValue?: number
  customPayoutAmount?: number
  customRequestId?: string
}

type BaseLegacyProduct =
  GetItemPageContentQuery['getItemPageContent']['product']
export interface SelectedLegacyProduct extends BaseLegacyProduct {
  // customMarketValue?: number
  customPayoutAmount?: number
  customRequestId?: string
}

export interface SelectedItemsType extends DealItemCreateArgs {
  picture?: UploadFile
  customRequestId?: string
  customDealId?: string
  contentCategories?: Maybe<ContentCategories>
  itemCategory?: Maybe<
    Omit<ItemCategory, 'parentCategories'> & {
      parentCategories?: { name: string }[]
    }
  >
  isBulkyGood?: Maybe<boolean>
  selectedVariant?: VariantsFragmentFragment
}

export interface DealItemsType {
  _id: string
  title: string
  algoliaReference?: string | null | undefined
  material?: string | null | undefined
  ean?: string | null | undefined
  media?: FileMetadata[] | null | undefined
  note?: string | null | undefined
  itemCategoryId: string
}

export interface RegistrationFormState {
  firstname: string
  lastname: string
  phone: string
  email: string
  password: string
  passwordRepeat: string
  dateOfBirth: Date | undefined
}

export interface KnpDebt {
  fileReferenceNumber?: string
  loadedKnpDebt?: {
    fileReferenceNumber: string
    debtAmount: number
  }
}

export enum EVehicleCategory {
  CAR = '612e510d880278dd21d87ddf',
  CAR_STORED = '618b9219697fcfab9a619b1b',
  MOTORCYCLE = '616e8d5bf99947cba49f492b',
  MOTORCYCLE_STORED = '618b93f24466a86c06e73a7e',
}

export enum MainCategoryType {
  ELECTRONICS = 'Elektronik',
  WATCHES = 'Uhren',
  JEWELLERY = 'Schmuck',
  COINS_AND_BARS = 'Münzen & Barren',
  BAGS = 'Taschen',
  OTHER = 'Sonstiges',
}

export type DealEventType = Exclude<DealEvent['__typename'], undefined>

/*
    DEFAULT SEO PARAMS - CAN BE OVERWRITTEN ON A PER-PAGE BASIS
    Documentation: https://github.com/garmeeh/next-seo#readme
 */
import { DefaultSeoProps } from 'next-seo'
import config from '@/config'
import { ERegionCode } from '@/types/gql/graphql'

export const regions = {
  [ERegionCode.At]: {
    regionCode: 'at',
    baseUrl: config.rootUrl.austria,
    defaultLocale: 'de',
    locales: ['en'],
  },
  [ERegionCode.De]: {
    regionCode: 'de',
    baseUrl: config.rootUrl.germany,
    defaultLocale: 'de',
    locales: ['en'],
  },
}

export const getDefaultSeoProps = (
  regionCode: ERegionCode,
  path: string,
  locale: string,
): DefaultSeoProps => {
  if (!(regionCode in regions)) {
    throw new Error('Region is invalid for SEO props')
  }

  const seo = {
    title: 'CASHY',
    description:
      'Bei CASHY kannst du deine Gegenstände verkaufen oder dir Geld dafür ausleihen (Pfandkredit). Du erhältst sofort Geld - in bar ausbezahlt oder auf dein Konto!',
  }

  const noRobots = config.env !== 'production'
  return {
    canonical: getDefaultCanonical(regionCode, path, locale),
    openGraph: {
      type: 'website',
      locale: `${locale}_${regions[regionCode].regionCode.toUpperCase()}`,
      url: new URL(path, regions[regionCode].baseUrl).href,
      site_name: 'CASHY',
      title: seo.title,
      images: [
        {
          url: 'https://storage.googleapis.com/cashy-api-v2-content/cashy_pfandkredit_ankauf_303b1af1a8/cashy-pfandkredit-ankauf.png',
          width: 1200,
          height: 630,
          alt: 'Cashy Ankauf Pfandleihe',
        },
      ],
    },
    ...(noRobots
      ? {
          dangerouslySetAllPagesToNoIndex: true,
          dangerouslySetAllPagesToNoFollow: true,
          dangerouslyDisableGooglebot: true,
        }
      : {}),
  }
}

export function getLanguageAlternates(
  regionCodes: ERegionCode[],
  path: string,
) {
  const languageAlternates = regionCodes.flatMap(
    (regionCode): { hrefLang: `${string}-${string}`; href: string }[] => {
      const { baseUrl, defaultLocale, locales } = regions[regionCode]
      const url = new URL(path, baseUrl)

      url.search = ''
      url.hash = ''

      return [
        {
          hrefLang: `${defaultLocale}-${regionCode.toLowerCase()}`,
          href: url.href,
        },
        ...locales.map((locale) => {
          const url = new URL(`${locale}${path}`, baseUrl)

          url.search = ''
          url.hash = ''

          return {
            hrefLang: `${locale}-${regionCode.toLowerCase()}` as const,
            href: url.href,
          }
        }),
      ]
    },
  )

  const xDefaultRegionCode = [ERegionCode.De, ERegionCode.At].find((code) =>
    regionCodes.includes(code),
  )
  if (!xDefaultRegionCode) {
    throw new Error()
  }

  const xDefaultUrl = new URL(`en${path}`, regions[xDefaultRegionCode].baseUrl)
  xDefaultUrl.search = ''
  xDefaultUrl.hash = ''

  languageAlternates.push({
    hrefLang: 'x-default',
    href: xDefaultUrl.href,
  })

  return languageAlternates
}

export const getDefaultCanonical = (
  regionCode: ERegionCode,
  path: string,
  locale: string,
) => {
  const regionOpts = regions[regionCode]
  const url = new URL(
    regionOpts.defaultLocale === locale ? path : locale + path,
    regionOpts.baseUrl,
  )

  url.search = ''
  url.hash = ''

  return url.href
}

import React from 'react'

type Props = {
  primaryHeader: React.ReactNode
  subHeader?: React.ReactNode
  navMenu?: React.ReactNode
}

export const HeaderLayout = ({ primaryHeader, subHeader, navMenu }: Props) => {
  return (
    <>
      <header className="sticky top-0 left-0 right-0 z-[1000] *:h-[var(--primary-header-height)]">
        {primaryHeader}
      </header>
      {subHeader && (
        <header className="sticky top-[var(--primary-header-height)] left-0 right-0 z-10 *:h-[var(--sub-header-height)]">
          {subHeader}
        </header>
      )}
      {navMenu}
    </>
  )
}

import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { MenuSectionStoryblok } from '@/types/storyblok-component-types'

export default function MenuSection({ blok }: { blok: MenuSectionStoryblok }) {
  if (!blok.list) return null
  return (
    <div {...storyblokEditable(blok)} className="flex flex-col">
      <span className="text-lg font-bold uppercase mb-2">{blok.title}</span>
      <div className="flex flex-col gap-2">
        {blok.list?.map((item) => (
          <StoryblokComponent key={item._uid} blok={item} />
        ))}
      </div>
    </div>
  )
}

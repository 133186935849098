import AccountIcon from '../../assets/ico_account.svg'
import LoggedInAccountIcon from '../../assets/ico_account_logged_in.svg'
import { ModalDialogContext } from '../../context/modalDialogContext'
import LanguageSelector from '../LanguageSelector/LanguageSelector'
import Link from 'next/link'
import { useContext } from 'react'
import { customerState } from '@/app/auth/auth.state'
import Login from '@/app/auth/components/Login'
import { HeaderLogo } from '@/app/common/components/Header/Logo/Logo'
import { SearchButton } from '@/app/common/components/Header/SeachButton/SearchButton'
import { useRecoilStateWithSSRFallback } from '@/app/common/recoil'
import { cn } from '@/utils/cn'
import MenuButton from './MenuButton/MenuButton'
import { MobileCartButton } from './MobileCartButton/MobileCartButton'
import styles from './header.module.css'

type Props = {
  menuOpen: boolean
  toggleMenu: () => void
  itemsCount: number
  itemsTotalAmount: string
  isCheckout?: boolean
}

const headerPrefix = 'header'

export const MobilePrimaryHeader = ({
  menuOpen,
  toggleMenu,
  itemsCount,
  itemsTotalAmount,
  isCheckout,
}: Props) => {
  const [customer] = useRecoilStateWithSSRFallback(customerState, undefined)
  const modal = useContext(ModalDialogContext)
  const isLoggedIn = !!customer

  const handleLoginClick = () => {
    modal.open(<Login />, { variant: 'full' })
  }

  return (
    <div
      className={cn(styles.mobilePrimaryHeader, 'md:hidden grid bg-white z-0')}
    >
      <div className="[grid-area:logo] h-full flex items-center">
        <MenuButton menuOpen={menuOpen} toggleMenu={toggleMenu} />
        <HeaderLogo className="pl-2 pr-1" />
      </div>

      <div
        className={cn(
          '[grid-area:cart] h-full flex flex-row justify-end items-center gap-0.5',
          isCheckout && 'hidden',
        )}
      >
        <SearchButton />
        {itemsCount > 0 && (
          <MobileCartButton
            itemsCount={itemsCount}
            itemsTotalAmount={itemsTotalAmount}
          />
        )}

        {isLoggedIn ? (
          <Link
            id={`${headerPrefix}_profile`}
            href="/profile"
            className="h-full size-10 flex justify-center items-center"
          >
            <LoggedInAccountIcon width={20} height={20} />
          </Link>
        ) : (
          <button
            className="h-full size-10 flex justify-center items-center"
            onClick={handleLoginClick}
          >
            <AccountIcon width={20} height={20} />
          </button>
        )}

        <LanguageSelector />
      </div>
    </div>
  )
}

import Image, { ImageProps } from 'next/image'
import { AssetStoryblok } from '@/types/storyblok-component-types'
import { useStoryblokImageLoader } from './StoryblokImage'

export interface NextStoryblokImageProps
  extends Omit<ImageProps, 'src' | 'alt' | 'title' | 'loader'> {
  image: AssetStoryblok
  focal?: boolean
}

export default function NextStoryblokImage({
  image,
  focal,
  ...props
}: NextStoryblokImageProps) {
  const loader = useStoryblokImageLoader({
    image,
    aspectRatio:
      focal && props.width && props.height
        ? +props.width / +props.height
        : extractAspectRatio(image),
    width: props.width && +props.width,
    height: props.height && +props.height,
  })

  return (
    <Image
      {...props}
      src={image.filename}
      title={image.title}
      alt={image.alt ?? ''}
      loader={loader}
    />
  )
}

export function extractAspectRatio(image: AssetStoryblok) {
  const groups = image.filename.match(dimensionsRegExp)?.groups
  if (!groups) {
    console.error(image.filename)
    throw new Error('Failed to extract image dimensions')
  }

  return +groups.width / +groups.height
}

const dimensionsRegExp = /\/(?<width>\d+)x(?<height>\d+)\//

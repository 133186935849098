import { useRouter } from 'next/router'
import { useMemo } from 'react'
import useRegionCode from '@/helpers/useRegionCode'
import DesktopLanguageSelector from './DesktopLanguageSelector'
import SmallDevicesLanguageSelector from './SmallDevicesLanguageSelector'
import languages from './languages'

interface LanguageSelectorProps {
  isInFooter?: boolean
  footerContentColor?: string | undefined
}

export default function LanguageSelector({
  isInFooter,
  footerContentColor,
}: LanguageSelectorProps) {
  const router = useRouter()

  const handleLocaleChange = (locale: string) => {
    const { pathname, asPath, query } = router
    // change just the locale and maintain all other route information including href's query
    router.push({ pathname, query }, asPath, { locale })
  }

  const regionCode = useRegionCode()
  const availableLanguages = useMemo(
    () => languages.filter((lang) => lang.availableIn.includes(regionCode)),
    [regionCode],
  )

  const selectedLanguage = availableLanguages.find(
    (lang) => lang.locale === router.locale,
  )

  return (
    <>
      <DesktopLanguageSelector
        selectedLanguage={selectedLanguage}
        handleLocaleChange={handleLocaleChange}
        languages={availableLanguages}
        isInFooter={isInFooter}
      />
      <SmallDevicesLanguageSelector
        selectedLanguage={selectedLanguage}
        handleLocaleChange={handleLocaleChange}
        languages={availableLanguages}
        isInFooter={isInFooter}
        footerContentColor={footerContentColor}
      />
    </>
  )
}

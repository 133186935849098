import styled, { css } from 'styled-components'
import { trustedMediaHeight } from '@/domains/marketing/components/TrustedMedia/TrustedMediaCarousel'
import { SectionInner, SectionWrapper } from '@/style/components/Section'
import { media } from '@/style/helpers'

const defaultMaxHeight = '1000px'
export const HeroSectionWrapper = styled(SectionWrapper)<{
  $isTrustedMediaActive: boolean
  $richTextHeight: number
  $mobileMinHeight?: number
  $mobileMaxHeight?: number
}>`
  display: flex;
  flex-direction: column;

  position: relative;
  padding-bottom: 0 !important;
  overflow: hidden; // To hide header image radian when scale image

  width: 100%;
  height: ${(props) =>
    props.$isTrustedMediaActive
      ? `calc(100vh + ${trustedMediaHeight.xs}px)`
      : '100vh'};

  padding: 0px;

  @media (max-height: ${(props) => props.$richTextHeight}px) {
    height: ${(props) => props.$richTextHeight}px);
  }

  ${(props) => {
    if (!props.$mobileMinHeight) {
      return ''
    }

    if (props.$isTrustedMediaActive) {
      return css`
        min-height: calc(
          ${props.$mobileMinHeight}px + ${trustedMediaHeight.upToXs}px
        );

        ${media.xs} {
          min-height: calc(
            ${props.$mobileMinHeight}px + ${trustedMediaHeight.xs}px
          );
        }
      `
    } else {
      return css`
        min-height: ${props.$mobileMinHeight}px;
      `
    }
  }}

  ${(props) => {
    if (!props.$mobileMaxHeight) {
      return css`
        max-height: ${defaultMaxHeight};
      `
    }

    if (props.$isTrustedMediaActive) {
      return css`
        max-height: calc(
          ${props.$mobileMaxHeight}px + ${trustedMediaHeight.upToXs}px
        );

        ${media.xs} {
          max-height: calc(
            ${props.$mobileMaxHeight}px + ${trustedMediaHeight.xs}px
          );
        }

        ${media.md} {
          max-height: ${defaultMaxHeight};
        }
      `
    } else {
      return css`
        max-height: ${props.$mobileMaxHeight}px;

        ${media.md} {
          max-height: ${defaultMaxHeight};
        }
      `
    }
  }}

  ${media.md} {
    height: calc(100vh - var(--header-height));
  }

  ${SectionInner} {
    padding-top: 0rem;
    z-index: 1;
    position: relative;
  }
`

import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import CheckIcon from './big_check.svg'

const Wrapper = styled.div`
  text-align: center;
  padding-top: 48px;
`

const CheckIconStyled = styled(CheckIcon)`
  margin-bottom: 23px;
`

interface SuccessConfirmationProps {
  title: string
  description: string
}

export const SuccessConfirmation: React.FC<SuccessConfirmationProps> = (
  props,
) => {
  return (
    <Wrapper>
      <CheckIconStyled className="inline" />
      <div style={{ marginBottom: '10px' }}>
        <Text.md weight="semibold">{props.title}</Text.md>
      </div>
      <div>
        <Text.ms>{props.description}</Text.ms>
      </div>
    </Wrapper>
  )
}

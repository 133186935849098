import DefaultLanguageIcon from '../../assets/languageSelector/vector.svg'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  MainNavigation,
} from '@/app/common/components/Header/components'
import { cn } from '@/utils/cn'
import { Language } from './languages'

interface DesktopLanguageSelectorProps {
  selectedLanguage: Language | undefined
  handleLocaleChange: (locale: string) => void
  languages: Language[] | undefined
  isInFooter?: boolean
}

export default function DesktopLanguageSelector({
  selectedLanguage,
  handleLocaleChange,
  languages,
  isInFooter,
}: DesktopLanguageSelectorProps) {
  return (
    <Dropdown className={cn('h-full max-md:hidden', isInFooter ? 'ml-8' : '')}>
      <MainNavigation
        className={cn(
          'flex items-center gap-x-2',
          isInFooter ? 'text-white' : 'text-black',
        )}
      >
        <DefaultLanguageIcon fill={isInFooter ? '#ffffff' : '#000000'} />
        {selectedLanguage?.name}
      </MainNavigation>

      <DropdownMenu
        className={cn(
          'right-2',
          isInFooter ? 'top-[unset] bottom-full' : 'top-full bottom-[unset]',
        )}
      >
        {languages?.map(({ name, locale, Flag }) => (
          <DropdownItem
            key={locale}
            onClick={() => handleLocaleChange(locale)}
            className="flex items-center gap-x-1.5"
          >
            <div className="size-6 overflow-hidden rounded-full inline-block mr-2">
              <Flag width={24} height={24} />
            </div>
            {name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

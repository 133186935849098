import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import * as tracking from '@/helpers/tracking'
import { UnstyledButton } from '@/style/components/Button'
import Burger from './burger.svg'

interface ItemProps {
  menuOpen: boolean
  toggleMenu: Function
}

export default function MenuButton({ toggleMenu }: ItemProps) {
  const { t } = useTranslation()

  return (
    <UnstyledButton
      className="[grid-area:menu] flex justify-center items-center"
      id="header_open-sidebar"
      onClick={() => {
        toggleMenu()
        tracking.trackEvent('open_navigation')
      }}
    >
      <Burger className="size-10" />
      <span className="border-0 [clip:rect(1px,1px,1px,1px)] [clip-path:inset(50%)] h-px -m-px overflow-hidden p-0 break-normal !important w-px absolute">
        {t('common:header.menu_toggle_info')}
      </span>
    </UnstyledButton>
  )
}
